jQuery(document).foundation();
/*
These functions make sure WordPress
and Foundation play nice together.
*/

jQuery(document).ready(function() {

    // Remove empty P tags created by WP inside of Accordion and Orbit
    jQuery('.accordion p:empty, .orbit p:empty').remove();

	 // Makes sure last grid item floats left
	jQuery('.archive-grid .columns').last().addClass( 'end' );

	// Adds Flex Video to YouTube and Vimeo Embeds
  jQuery('iframe[src*="youtube.com"], iframe[src*="vimeo.com"]').each(function() {
    if ( jQuery(this).innerWidth() / jQuery(this).innerHeight() > 1.5 ) {
      jQuery(this).wrap("<div class='widescreen flex-video'/>");
    } else {
      jQuery(this).wrap("<div class='flex-video'/>");
    }
  });

});

jQuery("#slideshow > div:gt(0)").hide();

setInterval(function() {
  jQuery('#slideshow > div:first')
    .fadeOut(2000)
    .next()
    .fadeIn(2000)
    .end()
    .appendTo('#slideshow');
},  3500);


// Disable scroll on map iframe

jQuery('.location-map')
	.click(function(){
			jQuery(this).find('iframe').addClass('clicked')})
	.mouseleave(function(){
			jQuery(this).find('iframe').removeClass('clicked')});


// add class on page load

	jQuery(function() {
	  jQuery('.loadFade').addClass('fadeIn');
	});


// fade in on scroll

    jQuery(window).scroll( function(){
        jQuery('.scrollFade').each( function(i){
            var bottom_of_object = jQuery(this).offset().top + (jQuery(this).outerHeight() / 7);
            var bottom_of_window = jQuery(window).scrollTop() + jQuery(window).height();
            if( bottom_of_window > bottom_of_object ){
                jQuery(this).addClass("fadeIn")
            }
        });
    });


// add class on page load

	jQuery(window).scrollTop(jQuery(window).scrollTop()+1);



// Animate anchor scroll

  jQuery(function() {
    jQuery('a[href*="#"]:not([href="#"])').click(function() {
      if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
        var target = jQuery(this.hash);
        target = target.length ? target : jQuery('[name=' + this.hash.slice(1) +']');
        if (target.length) {
          jQuery('html, body').animate({
            scrollTop: target.offset().top
          }, 1000);
          return false;
        }
      }
    });
  });

// set cookie
  function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

// fade in callout and set cookie
  jQuery(function($) {
    var callout = $('.callout'),
        close   = $('.close-button');

    // $(window).load(function() {
    //   Foundation.Motion.animateIn(callout, 'fade-in');
    // });
    // set cookie to last one day
    close.click(function() {
      setCookie('dismissed-notice', 'yes', 1);
    });

  });

  const observer = lozad(); // lazy loads elements with default selector as '.lozad'
  observer.observe();